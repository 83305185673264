@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 880px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 10%) 1fr 0.8fr 12% 14% 120px 120px 50px;
}
